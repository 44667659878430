<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <b-form>
        <ServicesDescription
          :buttonText="buttonEditText"
          :isButtonIcon="isButtonIcon"
          :isFlexClass="isFlexClass"
          :deleteBtn="deleteBtn"
          :deletedButtonText="deletedButtonText"
          @connectService="sendRequestToUpdate"
          @deleteService="sendRequestToDelete"
        >
          <template #title> Параметры голосовой рассылки </template>
          <template #body>
            <b-form-group label="Название рассылки">
              <BaseTextInput v-model="dialerDetails.Name" placeholder="Введите название рассылки" />
            </b-form-group>
          </template>
        </ServicesDescription>
      </b-form>
    </slot>
  </the-wrapper>
</template>

<script>
import URL from '@/basUrl';
import { sendCustomerRequest, getDetailsWithQuery } from '@/api/common';
import { catchFunction, processResponse } from '@/additional_helpers/helpers';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { mapMutations } from 'vuex';

export default {
  errorMessage: 'Название рассылки не заполнено. Укажите название рассылки',

  data() {
    return {
      buttonEditText: 'Обновить параметры рассылки',
      deletedButtonText: 'Удалить рассылку',
      isFlexClass: false,
      isButtonIcon: false,
      deleteBtn: true,
      isSpinner: true,
      dialerId: this.$route.query.id,
      dialerDetails: {}
    };
  },

  components: {
    ServicesDescription,
    BaseTextInput,
    TheWrapper
  },

  created() {
    getDetailsWithQuery(URL.GET_DIALER_DETAILS, `id=${this.dialerId}`)
      .then((res) => {
        this.dialerDetails = { ...res.data[0] };
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  computed: {
    isDialerName() {
      return this.dialerDetails.Name.length > 0;
    }
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING', SAVE_MESSAGE: 'notifications/SAVE_MESSAGE' }),

    updateDialer() {
      sendCustomerRequest(URL.UPDATE_DIALER, this.createModelToSend())
        .then((res) => processResponse(res, 'Данные успешно обновлены'))
        .catch((e) => catchFunction(e));
    },

    deleteDialer() {
      getDetailsWithQuery(URL.UPDATE_DIALER, `id=${this.dialerId}`)
        .then((res) => {
          processResponse(res, `Голосовая рассылка ${this.dialerDetails.Name} удалена`);
          if (res.status === 200) this.$router.replace({ name: 'AutoDialerList' });
        })
        .catch((e) => catchFunction(e));
    },

    sendRequestToUpdate() {
      return this.isDialerName ? this.updateDialer() : catchFunction(this.$options.errorMessage);
    },

    sendRequestToDelete() {
      return this.isDialerName ? this.deleteDialer() : catchFunction(this.$options.errorMessage);
    },

    createModelToSend() {
      const {
        Name,
        Id,
        AbonentListId,
        PacketSettings,
        PhoneSettings,
        AbonentList
      } = this.dialerDetails;

      return JSON.stringify({
        Name,
        Id,
        AbonentListId,
        PacketSettings,
        PhoneSettings,
        AbonentList
      });
    }
  }
};
</script>

<style></style>
